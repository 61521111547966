<script>
  import { onMount, onDestroy } from 'svelte';
  import ListingMediaPreview from "../common/ListingMediaPreview.svelte";
  import { currentSearch} from './listingsStore.js';
  import ListingStarButtonComponentSelector from "../traveller/ListingStarButtonComponentSelector.svelte";
  export let listing
  let item

  const currencyFormatter = new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  $: imageUrls = listing.listing_medias.slice(0,7).map((url) => url);

  let url = `${listing.url}?start_date=${$currentSearch.start_date}&end_date=${$currentSearch.end_date}&adult_count=${$currentSearch.people}`

  const imageClass = `${listing.top_list ? "border-secondary border-2" : ""} ${listing.bump_list ? "border-primary border-2" : ""} w-full sm:w-56 h-64 sm:h-40 rounded-lg object-cover`

  const setNeutralColorForMarker = (marker) => {
    marker._element.querySelector("div").classList.remove("bg-secondary")
    marker._element.querySelector("div").classList.add("bg-primary")
    marker._element.classList.remove("z-20")
  }

  const setAccentColorForMarker = (marker) => {
    marker._element.querySelector("div").classList.add("bg-secondary")
    marker._element.querySelector("div").classList.remove("bg-primary")
    marker._element.classList.add("z-20")
  }

  const resetMarkerColors = () => {
    if(!window.markers) { return }

    window.markers.forEach((marker) => { setNeutralColorForMarker(marker) })
  }

  const setMarkerColor = () => {
    if(!window.markers) { return }

    const filteredMarkers = window.markers.filter( (marker) => {
      return marker._element.children[0].dataset.listingId == listing.id
    });

    window.markers.forEach((marker) => { setNeutralColorForMarker(marker) })

    filteredMarkers.forEach((marker) => { setAccentColorForMarker(marker) })
  }

  onMount(() => {
    item.addEventListener('mouseleave', () => {
      resetMarkerColors()
    })

    item.addEventListener('mouseenter', () => {
      setMarkerColor()
    })
  })

  onDestroy(() => {
    item.removeEventListener('mouseleave', () => {
      resetMarkerColors()
    })

    item.removeEventListener('mouseenter', () => {
      setMarkerColor()
    })
  })
</script>

<div class="relative">
  <div bind:this={item} class="block rounded-lg mr:0 lg:mr-2 sm:flex sm:w-auto listing-preview">

    <div class="relative block">
      {#if listing.listing_medias.length > 0}
        <div class="relative w-full bg-gray-100 rounded-lg sm:w-56">
          <ListingMediaPreview
            imageUrls={imageUrls}
            imageClass={imageClass}
          />

          {#if listing.top_listing }
            <div class="absolute flex items-center px-1 py-px text-[11px] font-semibold text-gray-600 left-2 top-2 rounded-xl bg-white/90">
              🌳
              <span class="ml-1">TopHôte</span>
            </div>
          {/if}
          <div class="absolute left-2 bottom-2">
            <div class="flex items-center justify-center inline w-5 h-5 bg-gray-800 rounded-full bg-opacity-90">
              <span class="text-xs font-semibold text-white">{listing.listing_medias.length}</span>
            </div>
          </div>
        </div>

      {:else}
        <div class="w-full h-48 bg-gray-100 rounded-t-lg sm:rounded-l-lg sm:rounded-r-none sm:w-56"></div>
      {/if}

    </div>

    <a href={url} target="_blank" class="relative flex flex-col justify-between w-full py-0 mt-2 mr-2 text-sm sm:w-full sm:mt-0 sm:ml-4">

      <div>
      {#if listing.property_type }
        <span class="text-xs font-medium text-gray-500">
          <span class="tag"> {listing.property_type}</span>
        </span>
      {/if}

      {#if listing.title }
        <h3 class="w-full mt-0 overflow-hidden text-base font-semibold whitespace-nowrap text-ellipsis text-primary-dark sm:font-semibold">
          {listing.title}
        </h3>
      {/if}


      <p class="w-full my-1 overflow-hidden font-medium sm:block whitespace-nowrap text-ellipsis">
        {listing.details}
      </p>

      <p>
        <span class="text-xs font-medium text-gray-500 align-middle">
          {listing.city} · {listing.state}
        </span>
      </p>

      <div class="flex items-baseline mt-2 overflow-auto gap-2">
        {#each listing.experiences.slice(0, 3) as experience}
          <div class="text-xs font-semibold text-gray-800 whitespace-nowrap tag bg-primary/20">{experience}</div>
        {/each}
        {#if listing.experiences.count > 3 }
          <div class="text-xs font-semibold text-primary-dark">(+{listing.experiences.length})</div>
        {/if}
      </div>

      </div>
      {#if listing.quote }
        <div class="flex items-center font-semibold gap-x-1">
          <div class="text-primary-dark">
            { currencyFormatter.format(listing.quote.total_price_per_night) } {listing.quote.currency} par nuit

          </div>
          <span>·</span>
          <div class="font-medium text-gray-500">
            { currencyFormatter.format(listing.quote.total_price) } {listing.quote.currency}
          </div>
        </div>
      {:else if listing.base_price }
        <div class="font-medium text-gray-500">À partir de <span class="font-semibold font-bold text-primary-dark">{listing.base_price} {listing.currency_for_display}</span> par nuit</div>
      {/if}

        <button class="w-full mt-4 sm:hidden btn-primary">Voir l'annonce</button>
      </a>

    <div class="absolute z-20 top-2 right-2 md:bottom-auto md:top-0 md:right-4">
      <ListingStarButtonComponentSelector listingId={listing.id} />
    </div>
  </div>
</div>

