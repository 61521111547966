<script>
  import { tick } from 'svelte';
  import { listings, loadListings, loading, error, pagination, currentSearch } from './listingsStore.js';
  import ListingPreview from "./ListingPreview.svelte";
  import Loader from "../common/Loader.svelte";

  const refreshListingsOrder = () => {
    $currentSearch.page = 1
    loadListings(true);
  }

  const loadPage = async (index) => {
    $currentSearch.page = index
    await tick(); // current search needs to be updated before loadListings
    loadListings(true, true, true);
  }
</script>

<div class="min-h-screen" id="listings-list">
  <select class="py-2 pr-8 mb-0 font-medium input-base rounded-3xl" bind:value={$currentSearch.order} on:change={refreshListingsOrder}>
    <option value="">Tri des résultats</option>
    <option value="price_desc">Plus cher en premier</option>
    <option value="price_asc">Moins cher en premier</option>
  </select>
  {#if $loading }
    <div class="flex items-center justify-center h-screen">
      <Loader />
    </div>
  {:else if $error}
      <div class="mx-auto mt-6 text-sm font-medium text-gray-700 md:mx-0 max-w-prose">
        <p class="text-lg font-bold">
          Une erreur est survenue.
        </p>
        <p>
          Vous pouvez relancer votre recherche, ou nous contacter si le problème persiste.
        </p>
      </div>
  {:else}
    {#if $listings.length == 0}
      <div class="mx-auto mt-6 text-sm font-medium md:mx-0 max-w-prose">
        <p class="text-lg font-bold">
          Aucune annonce ne correspond à votre recherche.
        </p>
        <p>
          Vous pouvez essayer de modifier votre recherche en utilisant les filtres ci-dessus.
        </p>
      </div>
    {:else}
      {#each $listings as listing}
        <div class="py-4 border-b border-gray-200">
          <ListingPreview listing={listing.data.attributes} />
        </div>
      {/each}
      {#if $pagination.pages < 6 && $pagination.pages > 1}
        <div class="flex justify-center py-4 text-gray-600 gap-2">
          {#each Array($pagination.pages) as _, index (index)}
            <button class="flex items-center justify-center w-8 h-8 p-2 text-sm font-bold border rounded-full { $currentSearch.page == index + 1 ? 'bg-primary text-white' : '' }" on:click={() => loadPage(index + 1)}>
              {index + 1}
            </button>
          {/each}
        </div>
      {/if}
      {#if $pagination.pages > 5}
        <div class="flex justify-center py-4 text-gray-600 gap-2">
          {#if $currentSearch.page > 1}
            <button class="flex items-center justify-center w-8 h-8 p-2 text-sm font-bold border rounded-full" on:click={() => loadPage(1)}>1</button>
            <span>...</span>
          {/if}
          {#if $currentSearch.page > 2}
            <button class="flex items-center justify-center w-8 h-8 p-2 text-sm font-bold border rounded-full" on:click={() => loadPage($currentSearch.page - 1)}>{$currentSearch.page - 1}</button>
          {/if}
          <button class="flex items-center justify-center w-8 h-8 p-2 text-sm font-bold text-white border rounded-full bg-primary" on:click={() => loadPage($currentSearch.page)}>{$currentSearch.page}</button>
          {#if $currentSearch.page < $pagination.pages}
            <button class="flex items-center justify-center w-8 h-8 p-2 text-sm font-bold border rounded-full" on:click={() => loadPage(Number($currentSearch.page) + 1)}>{Number($currentSearch.page) + 1}</button>
          {/if}

          <span>...</span>
          <button class="flex items-center justify-center w-8 h-8 p-2 text-sm font-bold border rounded-full" on:click={() => loadPage($pagination.pages)}>{$pagination.pages}</button>
        </div>
      {/if}
    {/if}
  {/if}
</div>
