<script>
  import { loaded, enabledFeatures } from '../traveller/enabledFeaturesStore.js';
  import ToggleFavoriteListing from './ToggleFavoriteListing.svelte'
  import ToggleCuratedListing from './ToggleCuratedListing.svelte'

  export let starIconHtml, closeButtonHtml, listingId;
  $: hasCuratedListsFeature = $enabledFeatures.includes('curated_lists')

</script>

{#if loaded }
  {#if hasCuratedListsFeature }
    <ToggleCuratedListing closeButtonHtml={closeButtonHtml} starIconHtml={starIconHtml} listingId={listingId} />
  {:else}
    <ToggleFavoriteListing starIconHtml={starIconHtml} listingId={listingId} />
  {/if}
{/if}
