import { writable, get } from "svelte/store";

export const enabledFeatures = writable([]);
export const loaded = writable(false);

const response = fetch("/enabled_features").then((response) => {
  const features = response.json().then((json) => {
    enabledFeatures.set(json.features);
  });
});
