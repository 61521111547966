<script>
  import { getHeaders } from "../headers.svelte";
  import BrowserCookies from 'js-cookie'
  import StarSolid from "../common/icons/StarSolid.svelte";
  import { processePotentialNotification } from "../common/notificationStore.js";

  export let listingId;

  const handleClickOnStar = async () => {
    const response = await fetch('/favorites', {
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify({listing_id: listingId})
    })

    const data = await response.json()

    processePotentialNotification(data)
    isPartOfFavorites = getFavoriteListings().includes(listingId)
  }

  const getFavoriteListings = () => {
    const favoriteCookie = BrowserCookies.get("favorites")

    if(favoriteCookie == undefined) {
      return []
    }

    const cookie = window.atob(favoriteCookie.split("--")[0])

    return JSON.parse(JSON.parse(window.atob(JSON.parse(cookie)._rails.message)))
  }

  let isPartOfFavorites = getFavoriteListings().includes(listingId)
</script>

<div
  on:click={handleClickOnStar}
  class:text-gray-600="{!isPartOfFavorites}"
  class:text-secondary="{isPartOfFavorites}"
  class="flex items-center justify-center w-8 h-8 bg-gray-100 border border-gray-100 rounded-full shadow hover:bg-gray-50">
  <StarSolid className="w-4 h-4 cursor-pointer" />
</div>
