import { tick } from "svelte";
import { writable, get } from "svelte/store";
import { getHeaders } from "../headers.svelte";
import { notification } from "../common/notificationStore.js";

export const listings = writable([]);
export const pagination = writable({});
export const loading = writable(false);
export const error = writable(false);
export const filtersOpen = writable(false);
export const currentSearch = writable({});

export const loadListings = async (
  updateHistory,
  replaceSSRListingsIfPresent,
  keepPagination
) => {
  if (!keepPagination) {
    currentSearch.set({ ...get(currentSearch), page: 1 });
    await tick();
  }

  const form = document.querySelector("form#listing-search-form");
  const listingsList = document.querySelector("#listings-list");

  let queryParams = new URLSearchParams(new FormData(form)).toString();

  loading.set(true);

  const req = await fetch(`/annonces?${queryParams}`, {
    method: "GET",
    headers: getHeaders(),
  });

  const status = await req.status;

  if (status != 200) {
    loading.set(false);
    listings.set([]);

    if (status == 404) {
      notification.set({
        alert: "Aucune annonce ne correspond à votre recherche.",
      });
    } else {
      error.set(true);

      notification.set({
        alert: "Une erreur est survenue, veuillez réessayer",
      });
    }

    return;
  }

  const data = await req.json();

  error.set(false);
  listings.set(data.listings);
  pagination.set(data.pagination);

  if (listingsList) {
    listingsList.scrollIntoView(true);
    window.scrollTo(0, 0);
  }

  loading.set(false);

  if (updateHistory) {
    window.history.pushState(
      { listings: get(listings), currentSearch: get(currentSearch) },
      "",
      `/search/?${queryParams}`
    );
  }

  document.title = `${data.pagination.total}
  ${data.pagination.total > 1 ? "annonces" : "annonce"}
  de locations de vacances pour ${get(currentSearch).people}
  ${get(currentSearch).people > 1 ? "personnes" : "personne"}
  `;

  if (replaceSSRListingsIfPresent) {
    if (!listingsList?.dataset?.ssrLoaded) {
      return;
    }

    const componentApp = require("./ListingsList.svelte");

    listingsList.replaceChildren();

    listingsList.setAttribute("ssr-loaded", false);

    const svelteComponent = new componentApp.default({
      target: listingsList,
      props: {},
    });

    window.initializedSvelteComponents["traveller"].push(svelteComponent);
  }
};
