<script>
  import { fade } from 'svelte/transition';
  import { onDestroy } from 'svelte';
  import { getHeaders } from "../headers.svelte";
  import Times from "../common/icons/Times.svelte";
  import StarSolid from "../common/icons/StarSolid.svelte";
  import { processePotentialNotification } from "../common/notificationStore.js";
  import Loader from "../common/Loader.svelte";

  export let listingId;

  let showModal = false;
  let curatedLists = [];

  const handleClickOnStar = () => {
    fetchCuratedList()
  }

  const fetchCuratedList = async () => {
    showModal = true
    const response = await fetch("/listes", { headers: getHeaders() })
    const data = await response.json()
    curatedLists = data.curated_lists
  }

  const addListingToList = async (list_url) => {
    const response = await fetch(`/listes/${list_url}/curated_listings`, {
      headers: getHeaders(),
      method: 'POST',
      body: JSON.stringify({listing_id: listingId})
    })

    if(response.status == 201 || response.status == 202) {
      showModal = false
    }

    const data = await response.json()

    processePotentialNotification(data)
  }

  const handleKeyDown = (event) => {
    switch(event.key) {
      case "Escape":
        showModal = false
      break;
    }
  }
  window.addEventListener("keyup", handleKeyDown)

  onDestroy(() => {
    window.removeEventListener("keyup", handleKeyDown)
  })

</script>

<div on:click={handleClickOnStar} class="flex items-center justify-center w-8 h-8 text-gray-600 bg-gray-100 border border-gray-100 rounded-full shadow cursor-pointer hover:bg-gray-50">
  <StarSolid className="w-4 h-4" />
</div>

{#if showModal}
  <div transition:fade="{{duration: 80}}" class="fixed top-0 left-0 z-50 w-full h-screen p-4" on:click={() => showModal = false} style="background: rgba(0,0,0,0.7);">
    <div on:click|stopPropagation class="container relative py-6 mx-auto rounded-lg shadow-xl bg-gray-50 max-w-screen-sm" style="height: calc(100vh - 6rem);">
      <div class="flex items-center justify-between px-6 pb-4 border-b border-gray-200">
        <h3 class="text-xl font-semibold text-primary">Vos listes</h3>

        <div class="flex" on:click={() => showModal = false}>
          <div class="p-2 bg-gray-200 rounded-full cursor-pointer hover:bg-gray-300 transition-all">
            <Times className="w-4 h-4 text-gray-600" />
          </div>
        </div>
      </div>

      <div class="px-6 overflow-auto">
        {#each curatedLists as list}
          <div class="py-4 border-b border-gray-200 grid grid-cols-3">
            <a href="/listes/{list.data.attributes.url}" target="_blank" class="max-w-[8rem] text-sm font-semibold underline text-primary">{list.data.attributes.name}</a>
            <span class="text-sm">{list.data.attributes.listing_count} logements</span>
            <button on:click|preventDefault={() => addListingToList(list.data.attributes.url)} class="btn">Ajouter à cette liste</button>
          </div>
        {:else}
          <div class="w-full mt-8 text-center">
            <Loader/>
          </div>
        {/each}
      </div>
    </div>
  </div>
{/if}
